import React from 'react'

import Container from './styles'
import {
  OnboardTitle,
  Button,
  ButtonsContainer,
  Icons,
  OnboardSubtitle,
} from '@antifraude/components'

export default function ({ history }) {
  function handleStart() {
    history.replace('/liveness-cam')
  }

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <OnboardTitle>Tire uma selfie</OnboardTitle>
      <OnboardSubtitle>Leia atentamente as instruções!</OnboardSubtitle>

      <table className="tip">
        <tbody>
          <tr>
            <td>
              <Icons icon="light" />
            </td>
            <td>
              Escolha um local com boa iluminação e mantenha seu rosto
              totalmente visível. Evite pontos fortes de luz, como uma lâmpada
              ou sol atrás de você.
            </td>
          </tr>
          <tr>
            <td>
              <Icons icon="light" />
            </td>
            <td>Retire qualquer acessório, como óculos e chapéus.</td>
          </tr>
          <tr>
            <td>
              <Icons icon="light" />
            </td>
            <td>
              Alinhe seu queixo com a parte inferior da marcação e mantenha seu
              rosto dentro da área marcada.
            </td>
          </tr>
        </tbody>
      </table>

      <ButtonsContainer>
        <Button onClick={handleStart} variant="primary">
          Li e entendi as instruções
        </Button>
      </ButtonsContainer>
    </Container>
  )
}
