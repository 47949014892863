import React from 'react'
import jwtDecode from 'jwt-decode'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from '@antifraude/config'
import { logout } from 'store/reducers/user'
import { setToken } from 'store/reducers/token'
import { toast } from 'react-toastify'

export default function ({ parent: Parent, child, matchProps, level, ...rest }) {
  const { token } = useSelector((state) => state.token)
  const router = useRouter()
  const dispatch = useDispatch()


  function returnToLogin() {
    router.history.replace({
      pathname: '/login',
      state: { from: router.location.pathname },
    })
  }


  function validateLevelLogin() {
      if (token) {
        const decoded = jwtDecode(token)

        if (level && decoded.identity.level !== level) {
          toast.error('Acesso não autorizado!')
          returnToLogin()
        }
        const current_time = Date.now() / 1000
        if (decoded.exp < current_time) {
          toast.warn('Seu acesso expirou, faça login novamente.')
          dispatch(setToken(''))
          dispatch(logout())
          returnToLogin()
          return null
        }
    } else {
      toast.error('Acesso não autorizado!')
      returnToLogin()
    }
  }


  validateLevelLogin()

  return <Parent component={child} {...rest} />
}
