import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;

    svg {
      color: ${(props) => props.theme.palette.primary};
    }
  }

  .text {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
    color: ${(props) => props.theme.palette.gray[700]};
  }

  .counter {
    font-size: 2rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[700]};
  }

  .icon {
    &.success {
      margin: 4rem 0;
    }

    &.error {
      svg {
        width: 10rem;
        height: 10rem;
        color: #e31010;
        margin-bottom: 2rem;
      }
    }

    &.not-finished {
      svg {
        width: 10rem;
        height: 10rem;
        color: #AAAAAA;
        margin-bottom: 2rem;
      }
    }
  }
`
