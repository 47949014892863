import React, { useCallback, useEffect, useState } from 'react'
import { Route } from 'react-router-dom'
import { motion } from 'framer-motion'
import { detect } from 'detect-browser'
import { useSelector, useDispatch } from 'react-redux'

import Container from './styles'
import { Icons, Button, ButtonsContainer } from '@antifraude/components'
import { setViewed } from 'store/reducers/desktop'
import { clientLogo } from '@antifraude/config'

const browser = detect()

export default ({ component: Component, usecamera, ...rest }) => {
  const { viewed } = useSelector((state) => state.desktop)
  const dispatch = useDispatch()

  const [iosNotSupported, setIosNotSupported] = useState(false)
  const [isDesktop, setIsDesktop] = useState(false)
  const [desktopAgree, setDesktopAgree] = useState(viewed)

  const handleDesktopAgree = useCallback(() =>{
    dispatch(setViewed())
    setDesktopAgree(true)
  }, [dispatch])

  useEffect(() => {
    if (browser.os === 'iOS' && browser.name !== 'ios') {
      setIosNotSupported(true)
    }

    if (browser.os !== 'iOS' && browser.os !== 'Android OS') {
      setIsDesktop(true)
    }

    if(usecamera === false){
      handleDesktopAgree()
    }
  }, [handleDesktopAgree, usecamera])

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Container>
          <div className={isDesktop ? 'mobile_container' : 'full_width'}>
            <motion.header
              className="header"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.4 }}
            >
              <img src={clientLogo} alt="" className="header__logo" />
            </motion.header>
            <main className="main">
              {!iosNotSupported && (
                <React.Fragment>
                  {isDesktop ? (
                    <React.Fragment>
                      {!desktopAgree ? (
                        <div className="desktop_warning">
                          <h3>Atenção!</h3>
                          <Icons icon="camera" />
                          <p>
                            Para realizar todo o cadastro, será necessário ter
                            acesso à uma webcam!
                          </p>
                          <ButtonsContainer>
                            <Button
                              variant="primary"
                              onClick={handleDesktopAgree}
                            >
                              Ok, entendi
                            </Button>
                          </ButtonsContainer>
                        </div>
                      ) : (
                        <Component {...matchProps} />
                      )}
                    </React.Fragment>
                  ) : (
                    <Component {...matchProps} />
                  )}
                </React.Fragment>
              )}

              {iosNotSupported && (
                <motion.p
                  className="not_supported"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <Icons icon="infoHollow" />
                  Este navegador não é suportado na plataforma iOS!
                  <br />
                  <small>Tente novamente utilizando o navegador Safari.</small>
                </motion.p>
              )}
            </main>
          </div>
        </Container>
      )}
    />
  )
}
