export const DASHBOARD_REQUEST = 'DASHBOARD_REQUEST'
export const DASHBOARD_SUCCESS = 'DASHBOARD_SUCCESS'
export const DASHBOARD_FAILURE = 'DASHBOARD_FAILURE'

const initialState = {
  list: [],
  pages: 0,
  loading: false,
  error: '',
}

export default function link(state = initialState, { type, payload }) {
  switch (type) {
    case DASHBOARD_REQUEST:
      return {
        ...state,
        loading: true,
        error: '',
      }
    case DASHBOARD_SUCCESS:
      return {
        list: payload.list,
        pages: payload.pages,
        loading: false,
        error: '',
      }
    case DASHBOARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    default:
      return state
  }
}

export function fetch_dashboard(payload) {
  return {
    type: DASHBOARD_REQUEST,
    payload,
  }
}
