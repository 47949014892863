import React from 'react'
import { useSelector } from 'react-redux'
import { Route } from 'react-router-dom'

import Container from './styles'

const logo = `assets/${process.env.REACT_APP_COMPANY}/logo.png`

export default ({ component: Component, parent: Parent, child, redirectlogged=true, ...rest }) => {
  const { token } = useSelector((state) => state.token)

  if (token && token.length && redirectlogged) {
      // TODO: Verificar se identity.level = 'company'
      // se sim, encaminhar para dashboard, se nao
      // pedir para logar
      // history.replace('/dashboard')
  }

  if (child){
    return <Parent component={child} {...rest} />
  }

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Container>
          <main>
            <div className="brand">
              <img src={logo} alt="" className="brand__logo" />

            </div>
            <Component {...matchProps} />
          </main>
        </Container>
      )}
    />
  )
}
