import React from 'react'
import { styled } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core'
import { clientTheme } from '@antifraude/config'

const StyledProgress = styled(CircularProgress)(() => {
  return({
    color: clientTheme.primary,
  })
})

export default function() {
  return <StyledProgress />
}
