import React from "react"

export function EllipseMask(props) {

  return (
    <React.Fragment>
      <svg id="ellipse">
        <defs>
          <mask id="mask" x="0" y="0" width="100%" height="100%">
            <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
            <ellipse cx="50%" cy="52%" rx="38%" ry="32%" />
          </mask>
        </defs>
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          mask="url(#mask)"
          fillOpacity='1'
          fill='#FFFFFF'
        />
        <ellipse
          cx="50%"
          cy="52%"
          rx="38%"
          ry="32%"
          fillOpacity={0}
          className={`ellipse animated-ellipse ${props.loading ? 'loading' : ''} ${props.success ? 'success' : ''}`}
        />
      </svg>
    </React.Fragment>
  )
}
