import React from 'react'

import { FailContainer } from './styles'
import { Button, Icons } from '@antifraude/components'

export default function ({ history, location }) {

  const { state } = location

  function handleRetry() {
    if(state && state.type){
      history.replace({
        pathname: '/ocr-upload',
        state: {type: state.type}
      })
    } else {
      history.replace('/ocr')
    }
  }

  return (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>FALHA NA LEITURA DO DOCUMENTO</h1>
      <h4>Tente novamente, atentando-se às instruções:</h4>

      <table className="tip">
        <tbody>
         <tr>
            <td>
              <Icons icon="info" />
            </td>
            <td>
              Utilize um <u>documento recente</u>, bem preservado, sem borrões,
               rasgões, riscos ou partes amassadas.
            </td>
          </tr>
          <tr>
            <td>
              <Icons icon="info" />
            </td>
            <td>
              Coloque o documento sem a capinha (caso tenha) sobre uma
              superfície plana.
            </td>
          </tr>
          <tr>
            <td>
              <Icons icon="info" />
            </td>
            <td>
              Não tire fotos contra a luz direta para evitar manchas de reflexos.
            </td>
          </tr>
          <tr>
            <td>
              <Icons icon="info" />
            </td>
            <td>Enquadre seu documento totalmente na foto.</td>
          </tr>
          <tr>
            <td>
              <Icons icon="info" />
            </td>
            <td>Envie uma foto nítida, evite fotos tremidas ou borradas.</td>
          </tr>
        </tbody>
      </table>

      <Button variant="primary" onClick={handleRetry}>
        Tentar novamente
      </Button>
    </FailContainer>
  )
}
