import { lighten, darken } from 'polished'
import { createTheme } from '@material-ui/core/styles'

import themes from './themes'
export let clientTheme = themes[process.env.REACT_APP_THEME]
export let clientLogo = '/images/logo.png'

export function changeClientTheme(brandTheme) {
    clientTheme.primary = brandTheme.color
    clientLogo = brandTheme.logo
}

export const mainTheme = {
    palette: {
        ...clientTheme,
        light: lighten(0.06, clientTheme.primary),
        dark: darken(0.12, clientTheme.primary),
        error: '#d93030',
        gray: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: '#e0e0e0',
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A100: '#d5d5d5',
            A200: '#aaaaaa',
            A400: '#303030',
            A700: '#616161',
        },
    },
}

export const materialTheme = createTheme({
    palette: {
        primary: {
            main: clientTheme.primary,
        },
    },
    props: {
        MuiButtonBase: {
            disableRipple: true,
        },
    },
})
