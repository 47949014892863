import React, { useEffect, useState, useRef, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@antifraude/components'
import { UploadContainer } from './styles'
import { getOcrTypes } from 'store/reducers/ocr'
import CnhOpen from 'assets/cnh_aberto.svg'
import RgFront from 'assets/rg_front.svg'
import RgBack from 'assets/rg_back.svg'
import RneFront from 'assets/rne_front.svg'
import RneBack from 'assets/rne_back.svg'

const options = {
  cnh: {title: "CNH Aberta", image: CnhOpen},
  rg_front: {title: "RG Frente", image: RgFront},
  rg_back: {title: "RG Verso", image: RgBack},
  rne_front: {title: "RNE Frente", image: RneFront},
  rne_back: {title: "RNE Verso", image: RneBack},
}

const supportedExts = ['jpeg', 'jpg', 'png']

export default function ({ history, location }) {
  const { token } = useSelector((state) => state.token)
  const { types } = useSelector((state) => state.ocr)
  const [error, setError] = useState('')
  const [currentDoc, setCurrentDoc] = useState(null)
  const imageRef = useRef()
  const dispatch = useDispatch()

  const { state } = location

  const goToUpload = useCallback((type) => {
    history.replace({
      pathname: '/ocr-upload',
      state: { type: type },
    })
  }, [history])

  const currentTypeIsEnable = useCallback((type) => {

    if(!types.includes(type)){
      if(type === 'rg_front') {
        goToUpload('rg_back')
      } if(type === 'rne_front') {
        goToUpload('rne_back')
      } else {
        history.replace({
          pathname: '/ocr-options',
        })
      }
    }
  }, [goToUpload, history, types])

  useEffect(() => {
    if(types.length > 0) {
      currentTypeIsEnable(state.type)
      const selected = options[state.type]

      const doc = {
        title: selected.title,
        image: selected.image,
        side: selected.side
      }
      setCurrentDoc(doc)
    } else {
      dispatch(getOcrTypes({ token }))
    }
  }, [currentTypeIsEnable, dispatch, state, token, types.length])


  useEffect(() => {
    if (state && state.error) {
      setError(state.error)
    }
  }, [state])

  function handleImage(imageRef) {
    var files = imageRef.current.files
    for (let i = 0; i < files.length; i++) {
      var file = files[i]

      const ext = file.name.substring(file.name.lastIndexOf('.') + 1)

      if (!supportedExts.includes(ext.toLowerCase())) {
        setError(
          'Formato de arquivo não permitido! ' +
          'Envie somente imagens no formato JPG ou PNG.' )
        return false
      }
    }

    previewImage(files[0])
  }


  function previewImage(file) {
    history.replace({
      pathname: '/ocr-preview',
      state: {
        title: currentDoc.title,
        file: file,
        type: state.type,
        side: currentDoc.side,
      },
    })
  }


  function handleChooseDocument() {
    history.replace({
      pathname: '/ocr-options'
    })
  }

  return (
    <UploadContainer
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      {currentDoc && (
        <React.Fragment>
          <p className="title">{currentDoc.title}</p>
          <p className="subtitle">
            Clique abaixo para tirar uma foto ou<br />selecionar uma imagem da sua galeria
          </p>

          <div className="doc_model">
            <img src={currentDoc.image} alt="" />
          </div>

          {["RG Frente", "RNE Frente"].includes(currentDoc.title) &&
            <p className="tip"><b style={{color: "#FF5500"}}>ATENÇÃO:</b><p style={{color: "#000"}}> A <b>frente</b> do documento é a face<br /> que contém a sua foto 3x4.</p></p>
          }

          {error && <p className="error">{error}</p>}

          <div className="file_container">
            <label htmlFor="browse" className="file_container__label">
              Selecione a imagem
            </label>
            <input
              type="file"
              id="browse"
              name="browse"
              className="file_container__input"
              onChange={() => handleImage(imageRef)}
              ref={imageRef}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
        </React.Fragment>
      )}
      <div className="change-doc">
          <Button variant="primary" onClick={handleChooseDocument}>
            Mudar Documento
          </Button>
        </div>
    </UploadContainer>
  )
}
