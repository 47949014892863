import React from 'react'

export default function ({ history }) {
  function goBack() {
    history.goBack()
  }

  return (
    <div>
      404{' '}
      <button type="button" onClick={goBack}>
        Voltar
      </button>
    </div>
  )
}
