import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 2rem;

  .mobile_container {
    display: block;
    margin-top: 4rem;
    width: 45rem;
    height: auto;
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    padding: 2rem;
    padding-top: 0;
  }

  .full_width {
    width: 100%;
  }

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    &__logo {
      width: 40%;
      height: auto;
      margin-bottom: 3rem;
    }
  }

  .main {
    .not_supported {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
      margin-top: 4rem;
      color: ${(props) => props.theme.palette.gray[600]};

      svg {
        color: ${(props) => props.theme.palette.gray[300]};
        width: 10rem;
        height: 10rem;
        margin-bottom: 3rem;
      }

      small {
        margin-top: 2rem;
        font-size: 1.6rem;
        font-weight: 600;
        color: ${(props) => props.theme.palette.gray[500]};
      }
    }

    .desktop_warning {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h3 {
        font-size: 2.6rem;
        text-transform: uppercase;
        color: ${(props) => props.theme.palette.primary};
        margin-bottom: 2rem;
      }

      svg {
        color: ${(props) => props.theme.palette.gray[300]};
        width: 10rem;
        height: 10rem;
        margin-bottom: 3rem;
      }

      p {
        font-size: 1.6rem;
        text-align: center;
        color: ${(props) => props.theme.palette.gray[600]};
        margin-bottom: 3rem;
      }
    }
  }

  .tip {
    border-collapse: collapse;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};
        }

        td {
          padding: 1.5rem 0;

          svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
            color: #fcba03;
          }
        }
      }
    }
  }
`
