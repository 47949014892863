import React from 'react'

import Container from './styles'
import {
  Button,
  ButtonsContainer,
  OnboardTitle,
  Icons,
} from '@antifraude/components'


export default function ({ history }) {

  function handleContinue() {
    history.replace({
      pathname: '/ocr-options'
    })
  }

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <span className="center">
        <OnboardTitle>Envie uma foto do seu Documento</OnboardTitle>
        <OnboardTitle>Dicas para garantir o melhor resultado:</OnboardTitle>
      </span>

      <table className="tip">
        <tbody>
          <tr>
            <td>
              <Icons icon="light" />
            </td>
            <td>
              Envie a foto do documento sem a capinha (caso tenha), sobre uma
              superfície plana.
              </td>
          </tr>
          <tr>
            <td>
              <Icons icon="light" />
            </td>
            <td>O seu documento deve ser enquadrado na foto.</td>
          </tr>
          <tr>
            <td>
              <Icons icon="light" />
            </td>
            <td>Envie uma foto nítida, evite fotos tremidas ou borradas.</td>
          </tr>
          <tr>
            <td>
              <Icons icon="light" />
            </td>
            <td>Sendo uma CNH Digital, a foto pode ser um print da primeira imagem do documento.</td>
          </tr>
        </tbody>
      </table>

      <ButtonsContainer>
        <Button
          onClick={handleContinue}
          variant={"primary"}
        >
          Sim, entendi!
          </Button>
      </ButtonsContainer>
    </Container>
  )
}
