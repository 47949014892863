import React, { useState, useEffect} from 'react';

import { CounterFrame } from './styles'

const Countdown = ({seconds}) => {
  const [counter, setCounter] = useState(parseInt(seconds));


  useEffect(() => {
    counter > 1 && setTimeout(() => setCounter(counter - 1), 1000)
  }, [counter])

  return (
    <CounterFrame>{counter}</CounterFrame>
  )
}

export default Countdown
