import { all, takeLatest } from 'redux-saga/effects'

import { QUIZ_REQUEST, QUIZ_SUBMIT} from 'store/reducers/quiz'
import { DASHBOARD_REQUEST } from 'store/reducers/dashboard'
import { handleLogin, handleLogout } from './auth'
import { AUTH_REQUEST } from 'store/reducers/auth'
import { USER_LOGOUT } from 'store/reducers/user'
import { OCR_REQUEST_TYPES } from 'store/reducers/ocr'

import { handleFetchQuestions, handleSubmitAnswers } from './quiz'
import { handleFetchPage } from './dashboard'
import { handleOcrTypes } from './ocr'
import { handleGetRequestResult } from './request'
import { GET_REQUEST_RESULT } from 'store/reducers/request'

export default function* root() {
  yield all([
    takeLatest(QUIZ_REQUEST, handleFetchQuestions),
    takeLatest(QUIZ_SUBMIT, handleSubmitAnswers),
    takeLatest(DASHBOARD_REQUEST, handleFetchPage),
    takeLatest(AUTH_REQUEST, handleLogin),
    takeLatest(USER_LOGOUT, handleLogout),
    takeLatest(OCR_REQUEST_TYPES, handleOcrTypes),
    takeLatest(GET_REQUEST_RESULT, handleGetRequestResult),
  ])
}
