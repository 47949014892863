import React, { useEffect, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { PreviewContainer } from './styles'
import { get, post } from 'service/api'
import { verifyTokenRole } from 'config/tokenRouter'
import { AnimatedCheck, CircularProgress } from '@antifraude/components'

export default function ({ history, location }) {
  const { token } = useSelector((state) => state.token)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState(null)
  const [showWaitMessage, setShowWaitMessage] = useState(false)

  const { title, file, type } = location.state

  const previewImage = useCallback(() => {
    var img = document.getElementById('preview')
    img.file = file
    var reader = new FileReader()
    reader.onload = (function (aImg) {
      return function (e) {
        aImg.src = e.target.result
      }
    })(img)
    reader.readAsDataURL(file)
  }, [file])

  useEffect(() => {
    previewImage()
  }, [previewImage])

  function chooseOption() {
    history.replace({
      pathname: '/ocr-upload',
      state: { type: type },
    })
  }

  function sizeOf(base64) {
    try {
      var base64str = base64.split('base64,')[1];
      var decoded = atob(base64str);
      return decoded.length
    } catch {
      return -1
    }
  }

  function handleResponseError(file_base64) {
    Sentry.captureException(
      new Error("Erro ao enviar documento"),
      scope => {
        scope.clear();
        scope.setTag("step", "ocr-upload");
        scope.setContext("context", {
          "info": file_base64 && file_base64.substr(0, 30),
          "bytes": sizeOf(file_base64)
        });
        return scope;
    });

    history.replace({
      pathname: '/ocr-fail',
      state: {type, error: "Erro ao enviar documento" },
    })
    return
  }


  async function handleSubmit(file_base64) {
    setLoading(true)
    setShowWaitMessage(true)
    setLoadingMessage("Lendo informações do Documento...")
    const res = await post({
      url: `/onboarding/ocr`,
      token,
      data: { base64: file_base64, type },
    })

    const ocrApproved = res && res.status === 200
    const requiresNextOcrStep = res && res.status === 201

    if (ocrApproved) {
      callDocumentValidation()
    } else if (requiresNextOcrStep) {
      verifyTokenRole(res.data.token, history, null)
    } else if (!res.data.token) {
      handleResponseError(file_base64)
    } else {
      verifyTokenRole(res.data.token, history, null)
    }
  }


  async function callDocumentValidation() {
    setShowWaitMessage(false)
    setLoadingMessage("Validando dados")
    const resValidation = await get({
      url: '/onboarding/ocr/validation',
      token,
    })

    if (resValidation.status === 200) {
      const {token, success} = resValidation.data

      if(success) {
        setSuccess(success)
        setLoadingMessage(null)
        verifyTokenRole(token, history, null)
      } else {
        history.replace({
          pathname: '/ocr-fail',
          state: {type: null },
        })
      }

    }
  }


  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const result = reader.result
      cb(result)
    };
    reader.onerror = function (error) {
      return null
    };
  }

  return (
    <PreviewContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <p className="title">{title}</p>
      <img id="preview" className="image" alt="" />
      <p className="question">O texto da imagem está bem legível?</p>

      <div className="buttons_container">
        <button type="button" className="no_button" onClick={chooseOption}>
          Não
        </button>
        <button type="button" className="yes_button" onClick={() => { getBase64(file, handleSubmit) }}>
          Sim
        </button>
      </div>

      {loading && (
        <>
          <div className="loading">
            <div className="loading__container">
            {!showWaitMessage? !success ? <CircularProgress /> : <AnimatedCheck /> : null }
            </div>
          </div>
          {(loading && loadingMessage) &&
            <div className="loading_text">
              <div className="loading_text__container">
                <p>{loadingMessage}</p>
                {showWaitMessage && <CircularProgress />}
                {showWaitMessage && <p><br />Por favor, aguarde. <br /><br /><b> Este processo pode levar até 60 segundos.</b></p>}
              </div>
            </div>
          }
        </>
      )}
    </PreviewContainer>
  )
}
