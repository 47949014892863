import { call, put } from 'redux-saga/effects'
import { get } from 'service/api'
import { OCR_TYPES } from 'store/reducers/ocr'


export function* handleOcrTypes(action) {
  const { token } = action.payload

  const res = yield call(get, {
    url: `/onboarding/ocr/types`,
    token
  })

  if (res.status === 200 && res.data.types) {
      const { types } = res.data

      yield put({
        type: OCR_TYPES,
        payload: types,
      })
  }
}
