import { useEffect, useCallback } from 'react'
import * as QueryString from 'query-string'
import { useDispatch } from 'react-redux'
import axios from 'service/axiosCustom'

import { serviceUrl } from '@antifraude/config'
import { setToken } from 'store/reducers/token'
import { verifyTokenRole } from 'config/tokenRouter'

export default function ({ location, history, changeTheme }) {

  const params = QueryString.parse(location.search)
  const dispatch = useDispatch()

  if (!params.signature) {
    history.replace({
      pathname: '/forbidden',
      state: { error: 'Link inválido!' },
    })
    return null
  }

  const verifySig = useCallback(
    async (signature) => {
      try {
        const res = await axios({
          method: 'POST',
          url: `${serviceUrl[process.env.REACT_APP_ENV]}/onboarding/validate`,
          headers: { 'Content-Type': 'application/json' },
          data: { signature },
        })

        const token = res.data.token

        if(res) {
          const brandTheme = await axios({
            method: 'GET',
            url: `${serviceUrl[process.env.REACT_APP_ENV]}/brand?signature=${signature}`,
            headers: {
              'Authorization': `Bearer ${res.data.token}`
            }
          })

          if(brandTheme.status === 200) changeTheme(brandTheme.data)
        }

        dispatch(setToken(token))
        return verifyTokenRole(token, history, null, {})

      } catch (e) {
        let state = {}
        if (e.response) {
          state = {
            error: e.response.data.detail,
            redirect: e.response.data.redirect_url,
          }
        } else {
          state = {
            error: 'Houve um problema na requisicao',
          }
        }

        history.replace({
          pathname: '/forbidden',
          state: state,
        })
      }
    },
    [changeTheme, dispatch, history],
  )

  useEffect(() => {
    verifySig(params.signature)
  }, [params.signature, verifySig])

  return null
}
