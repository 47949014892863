import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`
  .tip {
    border-collapse: collapse;
    font-size: 1.4rem;
    margin-top: 2rem;

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};
        }

        td {
          padding: 1.5rem 0;

          svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
            color: #fcba03;
          }
        }
      }
    }
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }
  .loading_text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    margin-top: 4em;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
      &__container {
        margin-top:0.5rem;
        background-color: white;
        padding: .3rem 1rem;
        border-radius: 20%;

        svg {
          height: 4rem;
          width: 4rem;
        }
      }
  }
`

export const CameraContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  & > div {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
  }

  .camera {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    @media (min-width: 500px) {
      width: 80vw;
      margin: 0 0 0 10vw;
    }
    @media (min-width: 650px) {
      width: 70vw;
      margin: 0 0 0 15vw;
    }
    @media (min-width: 768px) {
      width: 60vw;
      margin: 0 0 0 20vw;
    }
    @media (min-width: 870px) {
      width: 50vw;
      margin: 0 0 0 25vw;
    }
    @media (min-width: 1200px) {
      width: 40vw;
      margin: 0 0 0 30vw;
    }
    @media (min-width: 1440px) {
      width: 30vw;
      margin: 0 0 0 35vw;
    }
    @media (min-width: 1800px) {
      width: 25vw;
      margin: 0 0 0 37.5vw;
    }
  }

  .hidden-counter{
    display: none
  }

  .mask {
    box-shadow: 0px 0px 0px 999px rgba(0, 0, 0, 0.5);
    border: 4px solid white;
    border-radius: 50%;
    width: 70%;
    height: 65%;
    margin-top: 40%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;

    &.valid {
      border-color: #17cf3f;
    }
  }

  #ellipse {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    @media (min-width: 500px) {
      width: 80vw;
      margin: 0 0 0 10vw;
    }
    @media (min-width: 650px) {
      width: 70vw;
      margin: 0 0 0 15vw;
    }
    @media (min-width: 768px) {
      width: 60vw;
      margin: 0 0 0 20vw;
    }
    @media (min-width: 870px) {
      width: 50vw;
      margin: 0 0 0 25vw;
    }
    @media (min-width: 1200px) {
      width: 40vw;
      margin: 0 0 0 30vw;
    }
    @media (min-width: 1440px) {
      width: 30vw;
      margin: 0 0 0 35vw;
    }
    @media (min-width: 1800px) {
      width: 25vw;
      margin: 0 0 0 37.5vw;
    }
  }

  ellipse.animated-ellipse {
    stroke-dasharray: 50%;
    stroke-width: 4px;
    animation: dash 100s linear reverse infinite;

    &.loading {
      stroke: #E8E8E8;
    }

    &.success {
      stroke: ${(props) => props.theme.palette.primary};
    }
  }

  @keyframes dash {
    to {
      stroke-dashoffset: 100000;
    }
  }

  .cameraOverlay {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 2;

    &.off {
      display: none;
    }

    .statusMessage {
      padding: 1.5rem;
      background-color: #DEDEDE;
      border-radius: 0.4rem;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
      margin-top: 2rem;
      text-align: center
    }
  }

  .loading {
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading__container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      & span:last-child {
        font-size: 1.5rem;
        color: #676767;
        max-width: 60%;
        text-align: center;
      }
    }
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    transform: scaleX(-1);
  }

  canvas {
    display: none;
  }
`

export const FailContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.palette.primary};
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[600]};
    margin-bottom: 2rem;
  }

  .tip {
    border-collapse: collapse;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 2rem;

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};
        }

        td {
          padding: 1.5rem 0;
          text-align: justify;

          svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
            color: #fcba03;
          }
        }
      }
    }
  }
  .hint {
    border: 1px solid ${(props) => props.theme.palette.gray[200]};
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 0 2rem;
    margin-bottom: 4rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-bottom: 1rem;
      color: #fcba03;
    }

    p {
      font-size: 1.4rem;
    }
  }
`

export const CounterFrame = styled.div`
  position: absolute;
  top: calc(50vh - 50px);
  left: calc(50vw - 50px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: rgba(240, 240, 240, 0.6);
  color: #424242;
  font-weight: bold;
  font-size: 40px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 0.5px solid rgba(224, 224, 224, 0.8);
`
