import React, { useEffect, useState, useCallback } from 'react'

import Container from './styles'
import { Icons } from '@antifraude/components'

export default function ({ location }) {
  const [redirectTime, setRedirectTime] = useState(5)

  const { state } = location

  const handleRedirectTime = useCallback(() => {
    if (redirectTime === 0) {
      if (state.redirect) window.location.href = state.redirect
    } else {
      setTimeout(() => {
        setRedirectTime(redirectTime - 1)
      }, 1000)
    }
  }, [redirectTime, state])

  useEffect(() => {
    if(state && state.redirect) {
      handleRedirectTime()
    }
  }, [handleRedirectTime, state])

  return (
    <Container>
      <div className="icon">
        <Icons icon="errorHollow" />
      </div>
      <h1>{state && state.error}</h1>
      <h1>{(!state || !state.error) && "Acesso não autorizado"}</h1>
    </Container>
  )
}
