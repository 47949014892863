import React, {
  useState,
  useRef,
  useCallback,
} from 'react'
import moment from 'moment'
import * as Sentry from '@sentry/browser'
import Webcam from 'react-webcam'
import { AnimatedCheck, CountdownTimer, CircularProgress } from '@antifraude/components'
import { useSelector, useDispatch } from 'react-redux'
import { post } from 'service/api'
import { setToken } from 'store/reducers/token'
import { verifyTokenRole } from 'config/tokenRouter'
import { getFullFaceDescription } from 'service/face'
import { image64toCanvasRef } from '@antifraude/config'
import Countdown from './Countdown'
import { EllipseMask } from './Ellipse'

import { CameraContainer } from './styles'

export default function ({ history }) {
  const cameraRef = useRef()
  const canvasRef = useRef(null)
  const [cameraStarted, setCameraStarted] = useState(false)
  const { token } = useSelector((state) => state.token)
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [callbackExecuted, setCallbackExecuted] = useState(false)
  const [livenessSent, setLivenessSent] = useState(false)
  const [detectMessage, setDetectMessage] = useState('')
  const [validFace, setValidFace] = useState(false)
  const [sending, setSending] = useState(false)
  const [showCounter, setShowCounter] = useState(false)
  const [endedCapture, setEndedCapture] = useState(false)
  const { innerWidth: width } = window;
  const dispatch = useDispatch()
  // let retry = 0
  let countAttempts = 0

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const result = reader.result
      const parts = result.split(",")
      cb(parts[parts.length - 1])
    };
    reader.onerror = function (error) {
      return null
    };
  }

  const handleResponseError = useCallback((file_base64) => {
    Sentry.captureException(
      new Error("Erro ao enviar selfie"),
      scope => {
        scope.clear();
        scope.setTag("step", "liveness-upload");
        scope.setContext("context", {
          "info": file_base64 && file_base64.substr(0, 30),
          "bytes": sizeOf(file_base64)
        });
        return scope;
    });

    history.replace({pathname: '/liveness-fail'})
    return
  }, [history])

  const uploadFrame = useCallback(async (base64_file, landmarks, token) => {
    if (!livenessSent) {
      setLoading(true)
      setSending(true)

      const res = await post({
        url: '/onboarding/liveness',
        token,
        data: {
          landmarks: {
            mouth: landmarks.getMouth(),
            rightEye: landmarks.getRightEye(),
            leftEye: landmarks.getLeftEye(),
          },
          base64: base64_file,
          count_attempts: countAttempts++
        },
      })

      if (res && res.status === 200) {
        setSuccess(true)
        setTimeout(() => {
          dispatch(setToken(res.data.token))
          verifyTokenRole(res.data.token, history, res.data.redirect_url)
        }, 2000)
      } else {
        handleResponseError(base64_file)
      }
      setSending(false)
    }
    setLivenessSent(true)
  }, [countAttempts, dispatch, handleResponseError, history, livenessSent])

  function sizeOf(base64) {
    try {
      var base64str = base64.split('base64,')[1];
      var decoded = atob(base64str);
      return decoded.length
    } catch {
      return -1
    }
  }

  const sendFrame = useCallback(async (url, screenshot, faceBox, landmarks) => {

    await image64toCanvasRef(canvasRef.current, screenshot, faceBox)

    await fetch(screenshot)
      .then((res) => res.blob())
      .then((blob) => {
        getBase64(blob, (base64_file) => {
          return uploadFrame(base64_file, landmarks, token)
        })
      })
  }, [token, uploadFrame])

  const getFrame = useCallback (async (url, screenshot, faceBox, landmarks) => {
    await sendFrame(url, screenshot, faceBox, landmarks)
  }, [sendFrame])

  const findFace = useCallback(async () => {
    return new Promise(async (resolve) => {
      if (cameraRef.current && cameraRef.current.getScreenshot()) {

        const screenshot = cameraRef.current.getScreenshot()
        await getFullFaceDescription(screenshot, token, true).then(async (fullDesc) => {
          if (fullDesc) {
            setValidFace(true)

            await getFrame(
              '/register/frame',
              screenshot,
              fullDesc.detection._box,
              fullDesc.landmarks
            )

            resolve(true)
          }

          resolve(false)
        })
      } else {
        setValidFace(false)
        resolve(false)
      }
    })
  }, [getFrame, token])

  const main = useCallback(async () => {
    if (cameraRef.current && cameraRef.current.getScreenshot()) {
      await getFullFaceDescription(cameraRef.current.getScreenshot(), token).then(
        async (fullDesc) => {
          const minWidth = (width > 412 ? 412 : width) * 0.75
          const maxWidth = (width > 412 ? 412 : width) * 0.85

          if (fullDesc) {
            const face_box = fullDesc._box
            const faceWidth = face_box._width
            console.log(minWidth, faceWidth, maxWidth)
            if (faceWidth < minWidth) {
              setValidFace(false)
              setDetectMessage('Aproxime seu rosto')
              setTimeout(main, 300)
            } else if (faceWidth > maxWidth) {
              setValidFace(false)
              setDetectMessage('Afaste seu rosto')
              setTimeout(main, 300)
            } else {
              setDetectMessage('Mantenha o rosto na posição atual')
              setShowCounter(true)
              setTimeout(async () => {
                setDetectMessage('Capturando imagem...')
                setShowCounter(false)
                setValidFace(true)
                let descFound = false

                while (!descFound) {
                  descFound = await findFace()
                }
                setCameraStarted(false)
                setEndedCapture(true)
              },3000)
            }
          } else {
            setValidFace(false)
            setDetectMessage('Encaixe seu rosto na moldura')
            setTimeout(main, 300)
          }
        },
      )
    } else {
      setValidFace(false)
      setDetectMessage('Encaixe seu rosto na moldura')
      setTimeout(main, 300)
    }

  }, [findFace, token, width])

  const handleCameraStart = () => {
    setCameraStarted(true)
    main()
  }

  const handleTimeout = async () => {
    if (!callbackExecuted && !sending) {
      console.log('timeout!')
      dispatch(setToken(token))
      verifyTokenRole(token, history)
      setCallbackExecuted(true)
    }
    return null
  }

  return (
    <CameraContainer
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <div>
        <div className="hidden-counter">
          <CountdownTimer
            time={moment().add(30, 's')}
            callback={handleTimeout}
          />
        </div>
        <div className="camera">
          <Webcam
            audio={false}
            ref={cameraRef}
            minScreenshotHeight={700}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: 'user' }}
            onUserMedia={handleCameraStart}
          />
        </div>
        <canvas ref={canvasRef} id="canvas"></canvas>

        {showCounter && <Countdown seconds={3} />}

        {cameraStarted && (
          <React.Fragment>
            <div className={`cameraOverlay ${!detectMessage && 'off'}`}>
              <span className='statusMessage'>{detectMessage}</span>
            </div>
            <div className={`loadingOverlay ${loading && 'on'}`}>
              <CircularProgress className="loadingLoader" />
            </div>
            <EllipseMask loading={cameraStarted} success={validFace} />
            {/* <div className={`mask ${validFace ? 'valid' : ''}`} /> */}
          </React.Fragment>
        )}

        {(!cameraStarted && endedCapture) && (
          <div className="loading">
            <div className="loading__container">
              <span>{!success ? <CircularProgress /> : <AnimatedCheck />}</span>
              <span>{!success ? 'Aguarde, processando imagem... Isso pode levar algum tempo.' : 'Processamento concluído'}</span>
            </div>
          </div>
        )}

      </div>
    </CameraContainer>
  )
}
