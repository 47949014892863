import React from 'react'
import { useSelector } from 'react-redux'
import jwtDecode from 'jwt-decode'

import {
  Button,
  ButtonsContainer,
} from '@antifraude/components'
import { PreQuizContainer } from './styles'

export default function ({ history }) {
  const { token } = useSelector((state) =>  state.token)
  const identity = jwtDecode(token)

  let signature = identity.identity.signature

  const handleQuiz = async () => {
    history.replace({
      pathname: `/quiz/${signature}`,
      state: { token },
    })
  }

  return (
    <PreQuizContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >

      <h1>Quiz</h1>
      <h4>
        <br />
        Responda a algumas perguntas para comprovar a sua identidade.
        <br />
        <br />
        Após clicar no botão "Iniciar Quiz", você terá apenas alguns segundos para responder.
        <br />
        Esteja preparado!
      </h4>

      <ButtonsContainer>
        <Button onClick={handleQuiz} variant="primary" fullWidth >
          Iniciar Quiz
        </Button>
      </ButtonsContainer>
    </PreQuizContainer>
  )
}
