import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { FailContainer } from './styles'
import { resetQuiz } from 'store/reducers/quiz'

export default function ({ history }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(resetQuiz())
  }, [dispatch])

  return (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>Não é possível prosseguir</h1>
      <h4>O máximo de tentativas foi atingido.</h4>
      <br />
      <h4>
      Entre em contato através de um de nossos atendimentos
        para mais informações.
      </h4>
    </FailContainer>
  )
}
