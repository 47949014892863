import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import * as Sentry from '@sentry/browser'

import { CircularProgress } from '@antifraude/components'
import { useSelector, useDispatch } from 'react-redux'
import { setToken } from 'store/reducers/token'
import { post } from 'service/api'
import { verifyTokenRole } from 'config/tokenRouter'
import Container from './styles'

export default function ({ history, location }) {
  const { token } = useSelector((state) => state.token)
  const [sent, setSent] = useState(false)
  const dispatch = useDispatch()

  const { state } = location

  function sizeOf(base64) {
    try {
      var decoded = atob(base64);
      return decoded.length
    } catch {
      return -1
    }
  }

  const handleResponseError = useCallback((file_base64) => {
    Sentry.captureException(
      new Error("Erro ao enviar selfie"),
      scope => {
        scope.clear();
        scope.setTag("step", "liveness-upload");
        scope.setContext("context", {
          "info": file_base64 && file_base64.substr(0, 30),
          "bytes": sizeOf(file_base64)
        });
        return scope;
    });

    history.replace({pathname: '/liveness-fail'})
    return
  }, [history])


  const uploadFrame = useCallback(async (base64) => {
    const res = await post({
      url: '/onboarding/liveness',
      token,
      data: { base64 },
    })

    if (res && res.status === 200) {
      dispatch(setToken(res.data.token))
      verifyTokenRole(res.data.token, history)
    } else {
      handleResponseError(base64)
    }
  }, [dispatch, handleResponseError, history, token])

  useEffect(() => {

    if(state && state.base64 && !sent) {
      console.log("Envia frame")
      setSent(true)
      uploadFrame(state.base64)
    }

  }, [state, uploadFrame, sent])

  return (
    <Container>
        <div className="loading">
          <div className="loading__container">
            <CircularProgress />
          </div>
        </div>
        <div className="loading_text">
          <div className="loading_text__container">
            <p>Aguarde... Validando imagem!</p>
          </div>
        </div>
    </Container>
  )
}

