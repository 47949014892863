import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { motion } from 'framer-motion'
import moment from 'moment'

import Container from './styles'
import { fetchQuestions, submitAnswers, resetQuiz } from 'store/reducers/quiz'
import {
  OnboardTitle,
  Icons,
  CountdownTimer,
  CircularProgress
} from '@antifraude/components'
import { get } from 'service/api'

export default function ({ history }) {
  const { token } = useSelector((state) => state.token)
  const { requestId } = useParams()

  const quiz = useSelector((state) => state.quiz)

  const dispatch = useDispatch()
  const [questionIndex, setQuestionIndex] = useState(0)
  const [currentQuestion, setCurrentQuestion] = useState(null)
  const [showQuestion, setShowQuestion] = useState(false)
  const [answers, setAnswers] = useState([])
  const { questions, loading, error, finished, success, sending } = quiz

  useEffect(() => {
    if (questions.length === 0  && !sending) {
      dispatch(fetchQuestions({ requestId, token }))
    }
  }, [dispatch, questions, requestId, sending, token])

  useEffect(() => {
    if (questionIndex === 0) {
      setAnswers([])
    }

    if (questions.length > 0) {
      setCurrentQuestion(questions[questionIndex])
      setShowQuestion(true)
    }
  }, [questions, questionIndex])

  useEffect(() => {
    if (finished) {
      dispatch(resetQuiz())
      if (success) {
        history.replace({
          pathname: '/success',
          state: {
            message: "Quiz validado com sucesso!",
            redirect: "/dashboard"
          }
        })
      }

    }
  }, [dispatch, finished, history, success])

  async function handleSubmit(items) {
    dispatch(submitAnswers({ requestId, token, items }))
  }

  function handleAnswer(answer) {
    setAnswers([...answers, answer])
    const totalQuestions = questions.length - 1

    if (answers.length < totalQuestions) {
      setShowQuestion(false)
      setQuestionIndex(questionIndex + 1)
    } else if (answers.length === totalQuestions) {
      setQuestionIndex(0)
      handleSubmit([...answers, answer])
    } else if (answers.length > totalQuestions){
      handleSubmit([answer])
    }
  }

  async function handleTimeout() {
    const res = await get({
      url: `/quiz/create-questions/${requestId}`,
      token
    })
    if (res.status === 200) {
      history.replace(`/quiz-finished/${requestId}`)
    }
    else {
      if (res.data.finished) {
        history.replace(`/quiz-finished/${requestId}`)
      }
      else {
        history.replace(`/quiz-fail/${requestId}`)
      }
    }
  }

  async function handleTimerEnded() {
    if (!sending) {
      handleTimeout()
    }
    return null
  }

  function multipleChoice(currentQuestion) {
    return (
      <div className="multiple-choice">
         {currentQuestion.answers.map((item, index) => (
           <button
             key={index}
             type="button"
             onClick={() => handleAnswer(item)}
           >
             <label>{item}</label>
           </button>
        ))}
      </div>
    )
  }

  function trueFalse(currentQuestion) {
    return (
      <div className="answers">
        {currentQuestion.answers.map((item, index) => (
          <button
            key={index}
            type="button"
            onClick={() => handleAnswer(item)}
            className={item === 'Verdadeiro' ? 'true' : 'false'}
          >
            {item === 'Verdadeiro' ? (
              <Icons icon="bigCheck" />
            ) : (
              <Icons icon="bigClose" />
            )}
          </button>
        ))}
      </div>
    )
  }

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <OnboardTitle>Quiz</OnboardTitle>

      {loading && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}

      {!loading && error && <div className="error">{error}</div>}

      {!loading && questions.length > 0 && (
        <React.Fragment>
          {showQuestion && (
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4 }}
            >
              <div className="quiz_container">
                <div className="quiz_timer">
                  <CountdownTimer
                    time={moment().add(30, 's')}
                    callback={handleTimerEnded}
                  />
                </div>

                <p className="dont-break-out">{currentQuestion.title}</p>

                {
                	currentQuestion.answers.length === 2
                	? trueFalse(currentQuestion)
                	: multipleChoice(currentQuestion)
                }

              </div>
            </motion.div>
          )}
        </React.Fragment>
      )}

      {sending && (
        <div className="sending">
          <div className="sending__container">
            <CircularProgress />
          </div>
        </div>
      )}
    </Container>
  )
}
