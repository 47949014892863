export const GET_REQUEST_RESULT = 'GET_REQUEST_RESULT'
export const SET_REQUEST_RESULT = 'SET_REQUEST_RESULT'

const initialState = {
  loading: false,
  result: null
}

export default function desktop(state = initialState, { type, payload }) {
  switch (type) {
    case GET_REQUEST_RESULT:
      return {
        ...state,
        loading: true
      }
    case SET_REQUEST_RESULT:
      return {
        loading: false,
        result: payload
      }
    default:
      return state
  }
}

export function getRequestResult(payload) {
  return {
    type: GET_REQUEST_RESULT,
    payload,
  }
}


