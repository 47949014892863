import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import { serviceUrl } from '@antifraude/config'
import { history } from 'store'

import axios from 'service/axiosCustom'

export const get = async ({ url, token, responseType }) => {
  return await service('GET', url, token, null)
}

export const post = async ({ url, token, data }) => {
  return await service('POST', url, token, data, null)
}

export const apiPut = async ({ url, token, data }) => {
  return await service('PUT', url, token, data, null)
}

function tokenIsExpired(token) {
  const decoded = jwtDecode(token)
  const current_time = Date.now() / 1000

  if (decoded.exp < current_time) {
    toast.error('Seu acesso expirou.')
    history.replace('/')
    return true
  }

  return false
}

const service = async (method, url, token, data = null, responseType = null) => {

  if (token || token === false ) {

    let Authorization = null
    if (token !== false) {
      tokenIsExpired(token)
      Authorization = 'Bearer ' + token
    }

    try {
      const res = await axios({
        method: method,
        url: `${serviceUrl[process.env.REACT_APP_ENV]}${url}`,
        headers: {
          'Content-Type': 'application/json',
          Authorization 
        },
        data,
        responseType: responseType || null
      })
      return {
        status: res.status,
        data: res.data,
      }
    } catch (e) {
      if (e.response) {
        return {
          status: e.response.status,
          message: e.response.data.message,
          token: e.response.data.token,
          data: e.response.data
        }
      } else {
        return {
          message: 'Houve um problema na requisicao',
        }
      }
    }
  } else {
    toast.error('Acesso não autorizado!')
  }
}

export const postFile = async ({ url, token, data }) => {
  return await serviceFile('POST', url, token, data)
}

export const putFile = async ({ url, token, data }) => {
  return await serviceFile('PUT', url, token, data)
}

export const serviceFile = async (method, url, token, data) => {
  if (token) {
    const decoded = jwtDecode(token)
    const current_time = Date.now() / 1000

    if (decoded.exp < current_time) {
      toast.error('Seu acesso expirou.')
      return history.replace('/')
    } else {
      try {
        const res = await axios({
          method,
          url: `${serviceUrl[process.env.REACT_APP_ENV]}${url}`,
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + token,
          },
          data,
        })
        return {
          status: res.status,
          data: res.data,
        }
      } catch (e) {
        if (e.response) {
          return {
            status: e.response.status,
            detail: e.response.data.message,
          }
        } else {
          return {
            message: 'Houve um problema na requisicao',
          }
        }
      }
    }
  } else {
    toast.error('Token de acesso inválido')
  }
}
