import styled from 'styled-components'
import { Dialog } from '@material-ui/core'

export default styled.div`
  margin-top: 4rem;

  .search-bar {
    display: flex;
    justify-content: space-around;
  }

  .search-bar > * {
    flex: 1;
    width: 50%;
    margin: 0;
  }

  .search-bar .form {
    justify-content: flex-end;
    display: flex;
    text-align: right;
  }

  .search-bar .search {
    width: 40px;
    padding: 10px; 
    text-align: left;
  }

  .search-bar .form > div {
    display: block;
    width: 150px;
    padding-right: 10px;
  }

  .search-bar .form > button {
    display: block;
    width: 35px !important;
    height: 35px !important;;
    margin: 15px;
    padding: 0;
  }

  span.logo_inova {
    text-align: right;
    width: 100%;
    display: block;
    width: 100%;
    padding: 0 1rem;

    img {
      width: 12rem;
      height: auto;
    }
  }

  .column-items {
    display: flex;
    flex-flow: column nowrap;

    &:not(:first-child) {
      margin-left: 1rem !important;
    }
  }

  .calendar react-calendar {
    width: 200px;
    heigth: auto;
  }

  .calendar {
    position: fixed;
    top: 30vh;
    left: 35vw;
    box-shadow: 0 0 1em #555555;
  }

  .align-left {
    text-align: left !important;
  }

  .column-container {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
  }

  .column-container-item {
    flex: 1;

    &:not(:first-child) {
      margin-left: 5px;
    }

    &.status-list {
      max-width: 100px;
    }

    &.status-list-documents {
      max-width: 170px;
    }
  }


  .action-buttons button {
    margin-left: 10px
  }

  .item-column {
    flex: 1;
    width: 100% !important;
    margin: 0 !important;


    &:not(:first-child) {
      margin-top: 0.5rem !important; 
    }
  }

  .right-buttons button {
    margin-left: 10px;
  }
  
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }
`

export const RowButton = styled.button`
  background-color: ${(props) => props.theme.palette.gray[200]};
  border: 0;
  border-radius: 0.8rem;
  outline: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;

  display: block;

  &:not(:first-child) {
    margin-left: 1rem;
  }

  &:only-child {
    margin: auto;
  }

  &:hover {
    background-color: ${(props) => props.theme.palette.gray[300]};
  }

  svg {
    margin: 0 !important;
  }

  span {
    display: block;
    margin-top: 0.2rem;
  }

  &.inline-infos {
    text-align: left;
    
    span {
      display: inline;
    }

    svg {
      width: 15px;
      heigth: 15px;
    }
  }
`


export const RequestModal = styled(Dialog)`
  .MuiDialog-paper {
    max-width: 90%;
    width: 60%;
    padding: 2rem;
    border-radius: 1.6rem;

    .header {
      span {
        display: block;
        font-size: 1.3rem !important;
        padding-bottom: 10px;
      }
    }

    .body {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      text-align: center;
      
      .image {
        flex: 1;
        width: 20rem;
        max-width: 20rem;
        height: auto;
        background-color: ${(props) => props.theme.palette.gray[200]};
        border-radius: 0.8rem;
        font-size: 1.1rem !important;
        text-align: center;

        &:not(:first-child) {
          margin-left: 1.5rem;
        }

        img {
          width: 100%;
        }
      }
  }
`
