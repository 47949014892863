import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { QUIZ_SUCCESS } from 'store/reducers/quiz'
import { get, post } from 'service/api'


export function* handleFetchQuestions(action) {
  const { requestId, token } = action.payload

  const res = yield call(get, {
    url: `/quiz/create-questions/${requestId}`,
    token
  })

  if (!res || res.status === 200) {
    console.log(res.data)
    yield put({
      type: QUIZ_SUCCESS,
      payload: res.data.questions,
    })
  } else if (res.data.error === "QUIZ_ALREADY_FINISHED"){
    yield put(push(`/result`))
  } else if (res.data.error === "QUIZ_BLOCKED") {
    yield put(push(`/quiz-finished/${requestId}`))
    } else if (res.data.error === "MAX_ATTEMPTS") {
    yield put(push(`/quiz-finished/${requestId}`))
  }
   else {
    yield put(push(`/quiz-fail/${requestId}`))
  }
}

export function* handleSubmitAnswers(action) {
  const { requestId, items, token } = action.payload

  const res = yield post({
    url: `/quiz/verify/${requestId}`,
    token,
    data: { answers: items },
  })

  console.log(res.data)

  if (res.status === 200) {
    if(res.data.questions){
      yield put({
        type: QUIZ_SUCCESS,
        payload: res.data.questions,
      })
    } else if (res.data.approved || res.data.finished) {
      yield put(push(`/result`))
    } else {
      yield put(push(`/quiz-fail/${requestId}`))
    }
  } else if (!res.finished && !res.valid) {
    yield put(push(`/quiz-fail/${requestId}`))
  } else {
    yield put(push(`/quiz-finished/${requestId}`))
  }
}
