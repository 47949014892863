import React from 'react'

import { FailContainer } from './styles'
import { Button, Icons } from '@antifraude/components'

export default function ({ history }) {
  function handleRetry() {
    history.replace('/liveness')
  }

  return (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>FALHA NO RECONHECIMENTO FACIAL</h1>
      <h4>Tente novamente, atentando-se ao seguinte:</h4>

      <table className="tip">
        <tbody>
          <tr>
            <td>
              <Icons icon="errorHollow" />
            </td>
            <td>
               FOTO ANTIGA - O documento deve apresentar uma foto relativamente recente.
            </td>
          </tr>
          <tr>
            <td>
              <Icons icon="errorHollow" />
            </td>
            <td>
               DOCUMENTO MAL PRESERVADO - O documento não pode apresentar borrões, rasgões, riscos ou amassados.
            </td>
          </tr>
          <tr>
            <td>
              <Icons icon="errorHollow" />
            </td>
            <td>
               FALHAS NA CAPTURA DO DOCUMENTO - A capa plástica deve ser retirada. Assegure-se que a foto ficou nítida e a imagem e os dados legíveis.
            </td>
          </tr>
        </tbody>
      </table>


      <Button variant="primary" onClick={handleRetry}>
        Tentar novamente
      </Button>
    </FailContainer>
  )
}
