import React, { useEffect, useState, useCallback } from 'react'
import { Formik, Field, Form } from 'formik'
import { motion } from 'framer-motion'
import { useSelector, useDispatch } from 'react-redux'
import { CircularProgress } from '@antifraude/components'
import { toast } from 'react-toastify'

import Container, { RowButton, RequestModal } from './styles'
import {
  Table,
  TableContainer,
  Pagination,
  Icons,
  Title,
  SearchBar,
  Button,
  TextInput
} from '@antifraude/components'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import { fetch_dashboard } from 'store/reducers/dashboard'
import { get } from 'service/api'
import { cleanCPF } from '@antifraude/config'

const logo_inova = `assets/inova/logo.png`

function formatdate(date, sep="/"){
  if (!date) return ''
  return date.getDate() +
    sep + (date.getMonth() + 1) +
    sep + date.getFullYear()
}

function subtractDays(date, subtract) {
  date.setDate(date.getDate()-subtract)
  return date
}

const initialValues = {
  date_start: formatdate(new Date()),
  date_end: formatdate(new Date()),
}

export default function () {
    const { token } = useSelector((state) => state.token)
    const dashboard = useSelector((state) => state.dashboard)
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [showDate, setShowDate] = useState(new Date());
    const [showCalendar, setShowCalendar] = useState(false);
    const [dateStart, setDateStart] = useState(subtractDays(new Date(), 1));
    const [dateEnd, setDateEnd] = useState(new Date());
    const [dateField, setDateField] = useState(null);
    const [page, setPage] = useState(1)
    const [pageLoading, setPageLoading] = useState(false)
    const [formData, setFormData] = useState(initialValues)
    const [showRequest, setShowRequest] = useState({})
    const [showModalRequest, setShowModalRequest] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)
    const [isImageEqual, setIsImageEqual] = useState(false)

    const { list, pages, loading } = dashboard

    const handleFetchPage = useCallback(
      async (number, search, dateStart, dateEnd) => {

        if (token) {
          dispatch(fetch_dashboard({
            token,
            page: number,
            search,
            dateStart,
            dateEnd
          }))
        }
      },
      [dispatch, token],
    )

    async function handleShowRequest(item) {
      setModalLoading(true)
      const res = await get({
        url: `/request/${item.id}`,
        token,
      })

      if (res.status === 200 && res.data) {
        setShowRequest(res.data)
        setShowModalRequest(true)
        setIsImageEqual(res.data.document.front === res.data.document.back)
      } else {
        toast.error('Erro ao carregar informações da solicitação')
        setShowModalRequest(false)
      }
      setModalLoading(false)
    }

    function handleCloseModal(){
      setShowModalRequest(false)
      setShowRequest({})
    }

    const formatDateFields = useCallback(() => {
      let ds = ''
      let de = ''

      if (dateStart)
        ds = formatdate(dateStart)
      if (dateEnd)
        de = formatdate(dateEnd)

      setFormData({
        date_start: ds,
        date_end: de
      })
    }, [dateEnd, dateStart])

    useEffect(() => {
      handleFetchPage(page, search, dateStart, dateEnd)
    }, [dateEnd, dateStart, handleFetchPage, page, search])

    useEffect(() => {
      formatDateFields()
    }, [setFormData, dateStart, dateEnd, formatDateFields])

    useEffect(() => {
      if (dateField === "date_start") setShowDate(dateStart)
      else if (dateField === "date_end") setShowDate(dateEnd)
    }, [dateEnd, dateField, dateStart, showDate])

    async function handleSearch(value) {
        setPage(1)
        setSearch(cleanCPF(value))
    }

    function handlePageChange(_, number) {
      setPage(number)
    }

    function handleSearchDate() {
      handleFetchPage(1, search, dateStart, dateEnd)
    }

    function handleChangeFieldDate(event) {
      setDateField(event.target.id)
      setShowCalendar(true)
    }

    function onCalendarChange(date) {
      if (dateField === "date_start"){
        if(dateEnd && date > dateEnd)
          toast.info('Data inicial deve ser menor ou igual a data final')
        else
          setDateStart(date)
      } else if (dateField === "date_end") {
        if(dateStart && date < dateStart)
          toast.info('Data final deve ser maior ou igual a data inicial')
        else
          setDateEnd(date)
      }

      setShowCalendar(false)
    }

    function returnColorComponent(color, disabled=false) {
      if (color === true) return <Icons icon="check" />
      else if (color === null && disabled) return <Icons icon="info" color={'grey'} />
      else if (color === null) return <Icons icon="info" />
      else if (color === false) return <Icons icon="error" />
      else return null
    }

    function downloadFile(content, filename) {
      const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${content}`
      const downloadLink = document.createElement('a')
      downloadLink.download = filename
      downloadLink.href = linkSource
      downloadLink.target = '_blank'
      downloadLink.click()
    }


    async function handleExportFile() {
      setPageLoading(true)

      let ds = ''
      let de = ''
      if (dateStart)
        ds = formatdate(dateStart, '-')
      if (dateEnd)
        de = formatdate(dateEnd, '-')

      const res = await get({
          url: `/dashboard/export?search=${search}&date_start=${ds}&date_end=${de}`,
          token,
          responseType: 'blob'
      })

      try {
          if (res && res.data) {
              downloadFile(res.data, "fca-relatorio.xlsx")
              setPageLoading(false)
          }
          else {
            setPageLoading(false)
            toast.error("Não foi possível exportar o relatório")
          }

      } catch (error) {
          setPageLoading(false)
          toast.error("Não foi possível exportar o relatório")
      }
    }

    return (<>
      <Container>
        <motion.div
          initial={{ opacity: 0, x: 30 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.4 }}
        >
          <span className="logo_inova">
            <img src={logo_inova} alt="Logo" />
          </span>
          <Title>Antifraude Dashboard</Title>
        </motion.div>

        <div className="search-bar">
          <div className="search">
            <SearchBar
                placeholder="Pesquisar"
                onSearch={(e) => handleSearch(e)}
            />
          </div>
          <Formik
            initialValues={formData}
            //validationSchema={validationSchema}
            onSubmit={handleSearchDate}
            enableReinitialize={true}
          >
            {(props) => {
              const { values } = props

            return (
              <Form className="form" onKeyDown={(e) => {
                if (e.key === 'Enter') { handleSearchDate(values) }
              }}
              >
                <Field
                  name="date_start"
                  label="Data Inicial"
                  onClick={(e) => handleChangeFieldDate(e)}
                  component={TextInput}
                  autoCorrect="off"
                  autoCapitalize="none"
                />
                <Field
                    name="date_end"
                    label="Data Final"
                    onClick={(e) => handleChangeFieldDate(e)}
                    component={TextInput}
                    autoCorrect="off"
                    autoCapitalize="none"
                  />
                  {/* <Button
                    variant="primary"
                    onClick={handleSearchDate}>
                    <Icons icon="search" />
                  </Button> */}
              </Form>
              )
          }}
          </Formik>
        </div>

        {showCalendar && (
            <div className="calendar">
              <Calendar
                on
                onChange={onCalendarChange}
                value={showDate} />
            </div>
          )}
        <h3>
        {search && (`Busca pelo CPF ou Nome "${search}". `)}
        </h3>
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4 }}
        >
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <th colSpan={1}>Solicitação</th>
                  <th colSpan={1}>Dados</th>
                  <th colSpan={3}>Validação</th>
                  <th colSpan={1}>Finalizado</th>
                  <th colSpan={1}>Aprovado</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 &&
                  <tr key={"-1"}>
                    <td colSpan={8}>Nenhum registro encontrado.</td>
                  </tr>
                }
                {list.map((item) => (
                  <tr key={item.id} onClick={() => handleShowRequest(item)}>
                    <td colSpan={1}>
                      {item.id}<br />
                      {item.created_at}
                    </td>
                    <td colSpan={1}>
                      <div className="align-left">
                        {item.name}<br />
                        <strong>CPF: </strong>{item.cpf}<br />
                        <strong>Telefone: </strong>{item.phone}<br />
                      </div>
                    </td>
                    <td colSpan={3}>
                      <div className="column-container">
                      {item.status_person && (
                          <div className="column-container-item status-list-documents">
                            <div className="column-items">
                                <span>Receita Federal</span>
                                <RowButton className="item-column">
                                      {returnColorComponent(item.status_person.person)}
                                      <span>Válido</span>
                                </RowButton>
                                <RowButton className="item-column inline-infos">
                                  <span>CPF </span>
                                  {returnColorComponent(item.status_person.person_cpf)}
                                  <br />

                                  <span>Nome </span>
                                  {returnColorComponent(item.status_person.person_name)}
                                  <br />

                                  <span>Data de Nascimento </span>
                                  {returnColorComponent(item.status_person.person_birthdate)}
                                  <br />

                                </RowButton>
                            </div>
                          </div>
                        )}
                        {item.status_token && (
                          <div className="column-container-item status-list">
                            <div className="column-items">
                                <span>Link</span>
                                <RowButton className="item-column">
                                    {returnColorComponent(item.status_token.sent)}
                                    <span>SMS Enviado</span>
                                </RowButton>
                                <RowButton className="item-column">
                                    {returnColorComponent(item.status_token.token)}
                                    <span>Link Clicado</span>
                                </RowButton>
                            </div>
                          </div>
                        )}
                        {item.status_document && (
                          <div className="column-container-item status-list-documents">
                            <div className="column-items">
                                <span>Documento</span>
                                <RowButton className="item-column">
                                      {returnColorComponent(item.status_document.document)}
                                      <span>Válido</span>
                                </RowButton>
                                <RowButton className="item-column inline-infos">
                                  <span>Nome </span>
                                  {returnColorComponent(item.status_document.document_name)}
                                  <br />

                                  <span>Data de Nascimento </span>
                                  {returnColorComponent(item.status_document.document_birthdate)}
                                  <br />

                                  <span>Nome da Mãe </span>
                                  {returnColorComponent(item.status_document.document_mother)}
                                  <br />

                                </RowButton>
                                <RowButton className="item-column">
                                <span>{item.status_document.ocr_attempts}</span>
                                    <span>Tentativa(s)</span>
                                </RowButton>
                            </div>
                          </div>
                        )}
                          {item.status_face && (
                            <div className="column-container-item status-list">
                              <div className="column-items">
                                  <span>Selfie</span>
                                  <RowButton className="item-column">
                                      {returnColorComponent(item.status_face.liveness, false)}
                                      <span>Liveness</span>
                                  </RowButton>
                                  <RowButton className="item-column">
                                      {returnColorComponent(item.status_face.facematch, false)}
                                      <span>Facematch</span>
                                  </RowButton>
                                  <RowButton className="item-column">
                                  <span>{item.status_face.liveness_attempts}</span>
                                    <span>Tentativa(s)</span>
                                </RowButton>
                              </div>
                            </div>
                          )}
                          {item.quiz && (
                          <div className="column-container-item status-list">
                            <div className="column-items">
                              <span>Quiz</span>
                              <RowButton className="item-column">
                                {returnColorComponent(item.quiz.quiz_approved || null)}
                                <span>Válido</span>
                              </RowButton>
                              <RowButton className="item-column">
                                <span>{item.quiz.quiz_attempts || 0}</span>
                                <span>Tentativa(s)</span>
                              </RowButton>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>

                    <td colSpan={1} className="centery">
                      <RowButton className="item-column">
                        {returnColorComponent(item.finished)}
                        { (item.expired === true) &&
                          <span>Finalizado por <br />Expiração do Link</span>}
                      </RowButton>
                    </td>

                    <td colSpan={1} className="centery">
                      <RowButton className="item-column">
                        {returnColorComponent(item.approved)}
                      </RowButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>

          <Pagination pages={pages} onChange={handlePageChange} />
        </motion.div>

        <div className="action-buttons">
          <Button
            variant="primary"
            onClick={handleExportFile}>
            Exportar
          </Button>
        </div>

        {(loading || pageLoading || modalLoading) && (
          <div className="loading">
            <div className="loading__container">
              <CircularProgress />
            </div>
          </div>
        )}
      </Container>
      <RequestModal open={showModalRequest} onClose={handleCloseModal}>
        <div className="header">
          <span>Id: {showRequest.id} </span>
          <span>CPF: {showRequest.cpf} </span>
          <span>Nome: {showRequest.name} </span>
        </div>

        <div className="body">
          <div className="image">
            <p>{isImageEqual ? 'Documento' : 'Frente do documento'}</p>
            {showRequest.document && showRequest.document.front && (
              <img alt="Documento frente" src={showRequest.document.front} />
            )}
          </div>

          {!isImageEqual && (
            <div className="image">
              <p>Verso do documento</p>
              {showRequest.document && showRequest.document.back && (
                <img alt="Documento verso" src={showRequest.document.back} />
              )}
            </div>
          )}

          <div className="image">
            <p>Selfie</p>
            {showRequest.selfie && (
              <img alt="Selfie" src={showRequest.selfie} />
            )}
          </div>
        </div>
      </RequestModal>
    </>)
  }
