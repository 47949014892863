import styled from 'styled-components'
import { motion } from 'framer-motion'

export default styled(motion.div)`
  .documents {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 4rem;

    li {
      border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};

      &:first-child {
        border-top: 1px solid ${(props) => props.theme.palette.gray[200]};
      }

      button {
        background-color: transparent;
        border: 0;
        outline: none;
        font-size: 1.6rem;
        padding: 3rem 2rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        font-weight: 600;
        color: ${(props) => props.theme.palette.gray[500]};
        border: 2px solid transparent;
        border-radius: 0.8rem;
        transition: all 0.2s ease-in-out;

        img {
          width: 4rem;
        }

        &.selected {
          border-color: ${(props) => props.theme.palette.primary};
          font-weight: 700;
          color: ${(props) => props.theme.palette.primary};
        }
      }
    }
  }
`

export const UploadContainer = styled(motion.div)`
  max-height: 75vh;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (min-height: 810px) {
    max-height: 80vh;
  }
  .change-doc {
    text-align: center;
  }

  .document-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  .change-doc{
    width: calc(100vw - 1rem);
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-top: 1rem;
  }

  .change-doc button {
    border-radius: 1.6rem;
    font-size: 12px;
  }

  .title {
    font-size: 2.8rem;
    width: 100%;
    text-align: center;
    font-weight: 700;
    margin-bottom: 1rem;
  }

  .subtitle {
    color: ${(props) => props.theme.palette.gray[500]};
    font-size: 1.4rem;
    font-weight: 500;
    margin-bottom: 1rem;
    text-align: center;
  }

  .tip{
    color: ${(props) => props.theme.palette.gray[500]};
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 2.5rem;
    text-align: center;
    padding: 0 2rem;
  }

  .doc_model {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    img {
      width: 22rem;
    }
    @media (max-height: 780px) {
      img {
        width: 18rem;
      }
    }
  }

  .error {
    color: #e31010;
    margin: 2rem;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 600;
  }

  .file_container {
    &__label {
      background-color: ${(props) => props.theme.palette.primary};
      color: white;
      font-weight: 600;
      text-transform: uppercase;
      border-radius: 1.6rem;
      font-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5rem;
      margin: 2rem;
      padding: 0.5rem 1rem;
    }

    &__input {
      display: none;
    }
  }
`

export const PreviewContainer = styled(motion.div)`
  padding: 2rem;

  .title {
    width: 100%;
    text-align: center;
    font-weight: 700;
    font-size: 2rem;
  }

  img {
    width: 100%;
    border-radius: 0.8rem;
    margin: 2rem 0;
  }

  .question {
    font-size: 1.4rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .buttons_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .no_button {
    background-color: ${(props) => props.theme.palette.gray[300]};
    color: ${(props) => props.theme.palette.gray[800]};
    border: 0;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 2rem;
    transition: all 0.3s ease;
    height: 4.2rem;
    flex: 1;
    margin-right: 2rem;
  }

  .yes_button {
    background-color: ${(props) => props.theme.palette.primary};
    color: ${(props) => props.theme.palette.contrast};
    border: 0;
    border-radius: 0.4rem;
    outline: none;
    cursor: pointer;
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0 2rem;
    transition: all 0.3s ease;
    height: 4.2rem;
    flex: 1;
  }

  .loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);

    &__container {
      background-color: white;
      padding: 1.5rem;
      border-radius: 50%;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }
  .loading_text {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    margin-top: 4em;
    width: 100%;
    height: calc(100vh - 20%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8);
    &__container {
      & p:first-child{
        margin-bottom: 1.6rem;
      }
      padding: .3rem 1rem;
      text-align: center;

      svg {
        height: 4rem;
        width: 4rem;
      }
    }
  }
`
export const FailContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  h1 {
    font-size: 2.4rem;
    color: ${(props) => props.theme.palette.primary};
    margin-bottom: 2rem;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    color: ${(props) => props.theme.palette.gray[600]};
    margin-bottom: 2rem;
  }
  .tip {
    border-collapse: collapse;
    font-size: 1.4rem;
    margin-top: 2rem;
    margin-bottom: 1rem;

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid ${(props) => props.theme.palette.gray[200]};
        }

        td {
          padding: 1.5rem 0;

          svg {
            width: 2.4rem;
            height: 2.4rem;
            margin-right: 1rem;
            color: #fcba03;
          }
        }
      }
    }
  }
  .hint {
    border: 1px solid ${(props) => props.theme.palette.gray[200]};
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 0 2rem;
    margin-bottom: 4rem;

    svg {
      width: 2.4rem;
      height: 2.4rem;
      margin-bottom: 1rem;
      color: #fcba03;
    }

    p {
      font-size: 1.4rem;
    }
  }
`

export const OptionsContainer = styled(motion.div)`

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;

  svg {
    color: ${(props) => props.theme.palette.primary};
  }
}

.text {
  color: ${(props) => props.theme.palette.gray[500]};
  font-size: 1.4rem;
}

.center {
  text-align: center
}

.options_container {
  p {
    font-size: 1.6rem;
    margin: 3rem 0;
  }

  .options {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: left;

    button {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;

      padding: 1.2rem 1rem;
      font-size: 1.2rem;
      outline: none;
      border-radius: 10px;
      font-weight: 600px;

      &.enabled {
        color: #000;
        background-color: transparent;
        box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.2);
        border: 2px solid transparent;
        border-color: #BBB;

        &:hover{
          border-color: #b9e600;
        }
      }

      &.disabled {
        color: #555;
        border: 0;
        background-color: #CCC;
      }

      &:first-child {
        margin-top: 5rem;
      }

      &:last-child {
        margin-bottom: 5rem;
      }

      &:not(:first-child) {
        margin-top: 2.5rem;
      }

      svg {
        flex: 1;
        color: #EEEEEE;
      }

      label {
        flex: 9;
      }
    }
  }
`
