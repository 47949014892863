import React from 'react'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'
import { motion } from 'framer-motion'

import Container from './styles'
import { login } from 'store/reducers/auth'
import { validationMessages } from '@antifraude/config'
import { Button, ButtonsContainer, TextInput } from '@antifraude/components'

const validationSchema = Yup.object({
  username: Yup.string().required(validationMessages.required),
  password: Yup.string().required(validationMessages.required),
})

const initialValues = {
  username: '',
  password: '',
}

const containerVariants = {
  initial: { y: 0, opacity: 0 },
  formInitial: { y: -40, opacity: 0 },
  formAnimate: { y: 0, opacity: 1 },
}

export default function ({ location, history }) {
  const auth = useSelector((state) => state.auth)

  const dispatch = useDispatch()

  const { state } = location
  const { error } = auth

  const handleSubmit = (values) => {
    const { username, password } = values
    dispatch(login({ username, password, from: state ? state.from : null }))
  }

  return (
    <motion.div
      variants={containerVariants}
      initial={state && state.fromForgot ? 'formInitial' : 'initial'}
      animate="formAnimate"
      transition={{ duration: 0.4 }}
    >
      <Container>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            return (
              <Form
                className="form"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(props.values)
                  }
                }}
              >
                <Field
                  name="username"
                  label="Email ou Nome de usuário"
                  component={TextInput}
                />
                <Field
                  type="password"
                  autoComplete="regtech-password"
                  name="password"
                  label="Senha"
                  component={TextInput}
                />

                {error && <div className="form__error">{error}</div>}

                <ButtonsContainer>
                  <Button
                    onClick={props.handleSubmit}
                    variant="primary"
                    fullWidth
                  >
                    Entrar
                  </Button>
                </ButtonsContainer>
              </Form>
            )
          }}
        </Formik>
      </Container>
    </motion.div>
  )
}
