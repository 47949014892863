import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'
import { resetQuiz } from 'store/reducers/quiz'
import { FailContainer } from './styles'
import { Button, Icons } from '@antifraude/components'

export default function ({ history }) {
  const dispatch = useDispatch()

  function handleRetry() {
    history.replace(`/quiz`)
  }

  useEffect(() => {
    dispatch(resetQuiz())
  }, [dispatch])

  return (
    <FailContainer
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.4 }}
    >
      <h1>Algo deu errado!</h1>
      <h4>Não conseguimos validar as respostas!</h4>

      <div className="hint">
        <Icons icon="light" />
        <p>Leia atentamente as questões e responda antes do tempo terminar.</p>
      </div>
      <Button variant="primary" onClick={handleRetry}>
        Tentar novamente
      </Button>
    </FailContainer>
  )
}
