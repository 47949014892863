export default {
  common: {
    primary: '#3A4752',
    contrast: 'white',
  },
  capef: {
    primary: '#3A4752',
    contrast: 'white',
  }
}
