import React, { useState, useEffect } from 'react'
import {
  Button,
  ButtonsContainer,
  OnboardTitle,
  OnboardSubtitle,
  CircularProgress
} from '@antifraude/components'
import Container from './styles'
import { getOcrTypes } from 'store/reducers/ocr'
import { useSelector, useDispatch } from 'react-redux'
import CnhFront from 'assets/cnh_front.svg'
import RgFront from 'assets/rg_front.svg'
import RneFront from 'assets/rne_front.svg'

export default function ({ history }) {

  const dispatch = useDispatch()

  const { token } = useSelector((state) => state.token)
  const { types, loading } = useSelector((state) => state.ocr)

  const [options, setOptions] = useState([])
  const [selectedDoc, setSelectedDoc] = useState(null)
  const [buttonEnabled, setButtonEnabled] = useState(false)

  useEffect(() => {
    if (types.length === 0) {
      dispatch(getOcrTypes({ token }))
    } else if (options.length === 0) {
      getOptions()
    }
  }, [types, options, dispatch, token])

  function getOptions() {
    const op = []

    const cnh = {id: 1, type: "cnh", label: "CNH", selected: false, image: CnhFront}
    const rg = {id: 2, type: "rg_front", label: "RG", selected: false, image: RgFront}
    const rne = {id: 3, type: "rne_front", label: "RNE", selected: false, image: RneFront}

    op.push(cnh)
    op.push(rg)
    op.push(rne)

    setOptions(op)
  }

  function chooseOption() {
    history.replace({
      pathname: '/ocr-upload',
      state: {
        label: selectedDoc.label,
        type: selectedDoc.type
      }
    })
  }

  function handleSelectDoc(doc) {
    setSelectedDoc(doc)
    setButtonEnabled(true)
  }

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >
      <OnboardTitle>
        Vamos iniciar com a validação do seu documento
      </OnboardTitle>

      <OnboardSubtitle>
        Para garantir o sucesso do processo, escolha dentre os documentos abaixo,
        aquele com a foto mais recente. Esse documento deve estar preservado,
        sem borrões, rasgões, riscos ou amassado.
      </OnboardSubtitle>

      <ul className="documents">
        {options.map((doc) => (
          <li key={doc.id}>
            <button
              type="button"
              className={
                selectedDoc && selectedDoc.id === doc.id ? 'selected' : ''
              }
              onClick={() => handleSelectDoc(doc)}
            >
              {doc.label}
              <img src={doc.image} alt="" />
            </button>
          </li>
        ))}
      </ul>

      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      ): (
        <ButtonsContainer>
          <Button
            onClick={buttonEnabled ? chooseOption : null}
            variant={buttonEnabled ? 'primary' : ''}
          >
            Continuar
          </Button>
        </ButtonsContainer>
      )}
    </Container>
  )
}
