import * as faceapi from 'face-api.js'

// import { post } from 'service/api'

// async function imageUploaded(file, token, cb) {
//   await cb(
//     file.replace("data:", "").replace(/^.+,/, ""),
//     token
//   )
// }

// // Salva imagem no backend para debugar a selfie capturada pelo disposivo.
// const saveImage = async (base64_file, token) => {
//   console.log('Sending to backend...')

//   await post({
//     url: '/save_image_test',
//     token,
//     data: { base64: base64_file },
//   })
//   console.log('File sended.')
// }

export async function getFullFaceDescription(blob, token, withFaceLandmarks=false) {
  let scoreThreshold = 0.3
  let inputSize = 512
  const OPTIONS = new faceapi.TinyFaceDetectorOptions({
    inputSize,
    scoreThreshold,
  })


  // Salva imagem no backend para debugar a selfie capturada pelo disposivo.
  // imageUploaded(blob, token, (base64_file, token) => {
  //   return saveImage(base64_file, token)
  // })

  let img = await faceapi.fetchImage(blob)
  let fullDesc = null

  if(withFaceLandmarks) {
    fullDesc = await faceapi.detectSingleFace(img, OPTIONS)
      .withFaceLandmarks()
  } else {
    fullDesc = await faceapi.detectSingleFace(img, OPTIONS)
  }

  return fullDesc
}
