import { call, put } from 'redux-saga/effects'
import { push } from 'connected-react-router'

import { SET_REQUEST_RESULT } from 'store/reducers/request'
import { get } from 'service/api'


export function* handleGetRequestResult(action) {
  const { token } = action.payload

  const res = yield call(get, {
    url: `/onboarding/result`,
    token
  })

  if (res && res.status === 200) {
    yield put({
      type: SET_REQUEST_RESULT,
      payload: res.data,
    })
  } else {
    yield put(push(`/forbidden`))
  }
}
