import React, { useEffect } from 'react'

import Container from './styles'
import { OnboardTitle, AnimatedCheck, Icons } from '@antifraude/components'
import { useDispatch, useSelector } from 'react-redux'
import { getRequestResult } from 'store/reducers/request'
import { Button, CircularProgress } from '@antifraude/components'

export default function ({ history }) {
  const { token } = useSelector((state) => state.token)
  const { result, loading } = useSelector((state) => state.request)
  const dispatch = useDispatch()

  useEffect(() => {
    if(!result) {
      dispatch(getRequestResult({ token }))
    }
  }, [dispatch, result, token])


  function handleRetry() {
    history.replace({
      pathname: '/ocr',
    })
  }

  const finished = result && result.finished
  const approved = result && result.approved
  const expired = result && result.expired

  return (
    <Container
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.4 }}
    >

      {(!result || loading) && (
        <div className="loading">
          <CircularProgress />
        </div>
      )}

      { result && !finished && (<>
          <OnboardTitle>Processo não finalizado!</OnboardTitle>

          <div className="icon not-finished">
            <Icons icon="errorHollow" />
          </div>

          <Button variant="primary" onClick={handleRetry}>
              Continuar Processo
          </Button>
      </> )}

      { result && finished && !approved && (<>
            <OnboardTitle>Processo Finalizado</OnboardTitle>

            <div className="icon error">
              <Icons icon="errorHollow" />
            </div>

            {expired && (<>
              <p className="text">O link para a prova de vida digital expirou.</p>
              <p className="text">Para gerar novo link, acesse o menu de <br /></p>
            </>)}

            {!expired && (<>
              <p className="text">Houve falha no Reconhecimento Facial ou na Leitura do Documento.</p>
            </>)}
        </>)}

      { result && finished && approved && (<>
          <OnboardTitle>PROCESSO FINALIZADO</OnboardTitle><br />

          <div className="icon">
            <AnimatedCheck />
          </div><br /><br />
          <p className="text">O processo de Reconhecimento Facial<br /> foi concluído com sucesso.</p>
      </> )}

    </Container>
  )
}
