export const DESKTOP_REQUEST = 'DESKTOP_REQUEST'

const initialState = {
  viewed: false,
}

export default function desktop(state = initialState, { type }) {
  switch (type) {
    case DESKTOP_REQUEST:
      return {
        viewed: true,
      }
    default:
      return state
  }
}

export function setViewed() {
  return {
    type: DESKTOP_REQUEST,
  }
}
