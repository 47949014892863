import React, { useState, useEffect } from 'react'
import { Route } from 'react-router-dom'
import { detect } from 'detect-browser'

import Container from './styles'

const browser = detect()

export default ({ component: Component, ...rest }) => {
  const [isDesktop, setIsDesktop] = useState(false)

  useEffect(() => {
    if (browser.os !== 'iOS' && browser.os !== 'Android OS') {
      setIsDesktop(true)
    }
  }, [])

  return (
    <Container>
      <div className={isDesktop ? 'mobile_container' : 'full_width'}>
        <Route
          {...rest}
          render={(matchProps) => <Component {...matchProps} />}
        />
      </div>
    </Container>
  )
}
