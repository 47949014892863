import React from 'react'
import { Switch, Route } from 'react-router-dom'

import DefaultLayout from 'ui/layouts/Default'
import DesktopDefault from 'ui/layouts/DesktopDefault'
import UnsignedLayout from 'ui/layouts/Unsigned'
import EmptyLayout from 'ui/layouts/Empty'
import ErrorLayout from 'ui/layouts/Error'
import ProtectedRoute from 'routes/ProtectedRoute'
import Login from 'ui/views/Login'

import VerifySignature from 'ui/views/VerifySignature'
import Ocr from 'ui/views/Ocr'
import Quiz from 'ui/views/Quiz'
import Dashboard from 'ui/views/Dashboard'
import QuizFail from 'ui/views/Quiz/Fail'
import QuizStart from 'ui/views/Quiz/Start'
import QuizFinished from 'ui/views/Quiz/Finished'
import OcrUpload from 'ui/views/Ocr/Upload'
import OcrPreview from 'ui/views/Ocr/Preview'
import OcrFail from 'ui/views/Ocr/Fail'
import OcrOptions from 'ui/views/Ocr/Options'
import NotFound from 'ui/views/404'
import Liveness from 'ui/views/Liveness'
import LivenessFail from 'ui/views/Liveness/Fail'
import LivenessCamera from 'ui/views/Liveness/Camera'
import LivenessValidate from 'ui/views/Liveness/Validate'
import Result from 'ui/views/Result'
import Forbidden from 'ui/views/Forbidden'

export default ({ changeTheme }) => {
  return (
    <Switch>
      <Route exact path="/" render={(props) => <VerifySignature {...props} changeTheme={changeTheme} />} />
      <UnsignedLayout exact path="/login" component={Login} />

      <ProtectedRoute path="/dashboard" 
        parent={DesktopDefault} 
        child={Dashboard}
        level="company"
      />

      <ProtectedRoute path="/ocr" 
        parent={DefaultLayout} 
        child={Ocr} 
        usecamera={false}
        level="client"
      />
      <ProtectedRoute path="/quiz/:requestId"
        parent={DefaultLayout} 
        child={Quiz} 
        level="client"
      />
      <ProtectedRoute path="/quiz-start" 
        parent={DefaultLayout} 
        child={QuizStart} 
        usecamera={false} 
        level="client"
      />
      <ProtectedRoute path="/quiz-fail/:requestId"
        parent={DefaultLayout} 
        child={QuizFail}
        level="client"
      />
      <ProtectedRoute path="/quiz-finished/:requestId"
        parent={DefaultLayout}
        child={QuizFinished} 
        level="client"
      />


      <ProtectedRoute
        path="/ocr-upload"
        parent={DefaultLayout}
        child={OcrUpload}
        level="client"
      />
      

    <ProtectedRoute
        path="/ocr-options"
        parent={DefaultLayout}
        child={OcrOptions}
        usecamera={false} 
        level="client"
      />

      <ProtectedRoute
        path="/ocr-preview"
        parent={EmptyLayout}
        child={OcrPreview}
        level="client"
      />
      <ProtectedRoute path="/ocr-fail" 
        parent={DefaultLayout} 
        child={OcrFail} 
        level="client"
      />

      <ProtectedRoute path="/liveness"
        parent={DefaultLayout}
        child={Liveness}
        level="client"
      />

      <ProtectedRoute path="/liveness-validate"
        parent={DefaultLayout}
        child={LivenessValidate}
        level="client"
      />

      <ProtectedRoute path="/liveness-fail"
        parent={DefaultLayout}
        child={LivenessFail}
        level="client"
      />
      <ProtectedRoute path="/liveness-cam"
        parent={EmptyLayout}
        child={LivenessCamera}
        level="client"
      />

      <ProtectedRoute path="/result" 
        parent={DefaultLayout} 
        child={Result} 
        usecamera={false}
        level="client"
      />
      
      <ErrorLayout path="/forbidden" component={Forbidden} />

      <ProtectedRoute 
        parent={DefaultLayout} 
        child={NotFound}
        level="client"
      />
    </Switch>
  )
}