export const OCR_TYPES = 'OCR_TYPES'
export const OCR_REQUEST_TYPES = 'OCR_REQUEST_TYPES'

const initialState = {
  types: [],
  loading: false,
}

export default function desktop(state = initialState, { type, payload }) {
  switch (type) {
    case OCR_REQUEST_TYPES:
      return {
        ...state,
        loading: true,
      }
    case OCR_TYPES:
      return {
        types: payload,
        loading: false,
      }
    default:
      return state
  }
}


export function getOcrTypes(payload) {
  return {
    type: OCR_REQUEST_TYPES,
    payload,
  }
}
