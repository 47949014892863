import styled from 'styled-components'

export default styled.div`
  .form {
    width: 35rem;
    color: #1d2d72;

    &__link_container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 2rem;
    }

    &__link {
      color: ${(props) => props.theme.palette.primary};
      font-size: 1.1rem;
      text-transform: uppercase;
      text-decoration: none;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
    }

    &__error {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 1.5rem;
      font-size: 1.2rem;
      font-weight: 600;
      color: ${(props) => props.theme.palette.error};
    }
  }
`
