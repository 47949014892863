import jwtDecode from 'jwt-decode'
import { toast } from 'react-toastify'

import { roles } from 'config/roles'

function getScreenByToken(token) {
  const { identity } = jwtDecode(token)
  return identity
}

export const verifyTokenRole = (token, history, redirect, state) => {

  if (token) {
    const identity = getScreenByToken(token)

    const { screen, data } = identity

    switch (screen) {
      case roles.DOCUMENT_OCR:
        if (data && data.type) {
          return history.replace({
            pathname: '/ocr-upload', 
            state: {type: data.type}
          })
        } else {
          return history.replace({
            pathname: '/ocr', 
            state
          })
        }
      case roles.DOCUMENT_OCR_RETRY:
        let state_ = state
        if (data && data.type)
          state_ = {type: data.type, ...state}

        return history.replace({
          pathname: '/ocr-fail', 
          state: state_ 
        })
      case roles.LIVENESS:
        return history.replace('/liveness')
      case roles.LIVENESS_RETRY:
        return history.replace('/liveness-fail')
      case roles.EMAIL_VALIDATION:
        return history.replace({
          pathname: '/email',
          state: state
        })
      case roles.QUIZ:
        return history.replace({
          pathname: '/quiz-start',
          state: {
            token: token,
            signature: identity.signature,
          },
        })
      case roles.FINISH:
        return history.replace({
          pathname: '/result',
          state: {
            ...state,
            redirect: redirect,
          },
        })
      default:
        return history.replace('/')
    }
  } else {
    return toast.error('Token inválido')
  }
}
