export const cnpjMask = [
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '/',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const cpfMask = [
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '.',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
]

export const phoneMask = [
  '(',
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const creditCardMask = [
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  ' ',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const cepMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

export const creditCardDateMask = [/\d/, /\d/, '/', /\d/, /\d/]

export const creditCardCVVMask = [/\d/, /\d/, /\d/]

export const maskCpfCnpj = (num) => {
  if (num) {
    num = num.toString()
    num = num.replace(/\D/g, '')

    switch (num.length) {
      case 4:
        num = num.replace(/(\d+)(\d{3})/, ' $1.$2')
        break
      case 5:
        num = num.replace(/(\d+)(\d{3})/, ' $1.$2')
        break
      case 6:
        num = num.replace(/(\d+)(\d{3})/, ' $1.$2')
        break
      case 7:
        num = num.replace(/(\d+)(\d{3})(\d{3})/, ' $1.$2.$3')
        break
      case 8:
        num = num.replace(/(\d+)(\d{3})(\d{3})/, ' $1.$2.$3')
        break
      case 9:
        num = num.replace(/(\d+)(\d{3})(\d{3})/, ' $1.$2.$3')
        break
      case 10:
        num = num.replace(/(\d+)(\d{3})(\d{3})(\d{1})/, ' $1.$2.$3-$4')
        break
      case 11:
        num = num.replace(/(\d+)(\d{3})(\d{3})(\d{2})/, ' $1.$2.$3-$4')
        break
      case 12:
        num = num.replace(/(\d+)(\d{3})(\d{3})(\d{4})/, ' $1.$2.$3/$4')
        break
      case 13:
        num = num.replace(
          /(\d+)(\d{3})(\d{3})(\d{4})(\d{2})/,
          ' $1.$2.$3/$4-$5',
        )
        break
      case 14:
        num = num.replace(
          /(\d{2})(\d{3})(\d{3})(\d{4})(\d+)/,
          ' $1.$2.$3/$4-$5',
        )
        break
      default:
    }
  }
  return num
}

export const currencyFormat = (num) => {
  return (
    'R$ ' +
    num
      .toFixed(2)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
  )
}

export const numberFormat = (num) => {
  return num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
}

export function phoneFormat(value) {
  value = value.replace(/\D/g, '')
  value = value.replace(/^(\d\d)(\d)/g, '($1) $2')
  if (value.length < 14) value = value.replace(/(\d{4})(\d)/, '$1-$2')
  else value = value.replace(/(\d{5})(\d)/, '$1-$2')
  return value
}

export const cleanCPF = (value) => {
  let searchTerm = value
  const cpfRegex = new RegExp('^[0-9.-]*$')

  if (cpfRegex.test(value.toLowerCase())) {
    searchTerm = value.replaceAll('.', '').replaceAll('-', '')
  }
  return searchTerm
}
