import React from 'react'
import { motion } from 'framer-motion'
import { Route } from 'react-router-dom'

import Container from './styles'

export default ({ component: Component, ...rest }) => {

  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.4 }}
        >
          <Container>
            <nav className="menu">
              <div className="menu__content" style={{minHeight: '50px'}}>
                {/* <img src={logo_min} alt="Logo" className="menu__logo" /> */}
              </div>
            </nav>

            <div className="content">
              <Component {...matchProps} />
            </div>
          </Container>
        </motion.div>
      )}
    />
  )
}
