import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  margin-bottom: 2rem;

  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;

    &__logo {
      width: 40%;
      height: auto;
      margin-bottom: 3rem;
    }
  }

  .mobile_container {
    margin-top: 4rem;
    width: 45rem;
    height: auto;
    box-shadow: 0 3px 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
    padding: 2rem;
    padding-top: 0;
    position: relative;
  }
`
