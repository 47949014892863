export const USER_REQUEST = 'USER_REQUEST'
export const USER_LOGOUT = 'USER_LOGOUT'
export const USER_SUCCESS = 'USER_SUCCESS'
export const USER_FAILURE = 'USER_FAILURE'

const initialState = { list: [], pages: 0 }

export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case USER_REQUEST:
      return { ...state, ...payload }
    case USER_LOGOUT:
      return {
        ...initialState,
      }
    case USER_SUCCESS:
      return {
        ...state,
        list: payload.list,
        pages: payload.pages,
        loading: false,
        error: '',
      }
    case USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    default:
      return state
  }
}

export function logout() {
  return {
    type: USER_LOGOUT,
  }
}

export function getUsers(payload) {
  return {
    type: USER_REQUEST,
    payload,
  }
}
