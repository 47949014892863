import { call, put } from 'redux-saga/effects'

import { DASHBOARD_SUCCESS, DASHBOARD_FAILURE } from 'store/reducers/dashboard'
import { get } from 'service/api'

function formatdate(date){
  if (!date) return ''
  return date.getDate() + 
    "-" + (date.getMonth() + 1) + 
    "-" + date.getFullYear()
}

export function* handleFetchPage(action) {
  const { token, page, search, dateStart, dateEnd } = action.payload

  const ds = formatdate(dateStart)
  const de = formatdate(dateEnd)

  const res = yield call(get, {
    url: `/dashboard?page=${page}&search=${search}&date_start=${ds}&date_end=${de}`,
    token,
  })
  
  if (res.status === 200) {
    yield put({
      type: DASHBOARD_SUCCESS,
      payload: { list: res.data.items, pages: res.data.total_pages },
    })
  } else {
    yield put({
      type: DASHBOARD_FAILURE,
    })
  }
}
